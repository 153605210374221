





















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import ProfileHeader from '@/components/Mobile/Profile/ProfileHeader.vue';
import StatsSection from '@/components/Mobile/Profile/StatsSection.vue';
import TourArchive from '@/components/Mobile/Profile/TourArchive.vue';
import Bio from '@/components/Mobile/Profile/Bio.vue';
import Place from '@/dtos/place';
import ClubChatWidget from '@/club/chat/ClubChatWidget.vue';

export default Vue.extend({
  components: {
    Scaffold,
    ProfileHeader,
    StatsSection,
    TourArchive,
    Bio,
    ClubChatWidget,
  },
  async mounted() {
    const dispatch = this.$store.dispatch;
    dispatch('toggleLoading', true);
    await dispatch('tour/bindToursRef');
    await dispatch('user/fetchUserFollowingCount', this.user.data.uid);
    dispatch('toggleLoading', false);
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      tours: 'tour/getTours',
      userFollowingCount: 'user/getUserFollowingCount',
    }),
    location(): Place | undefined {
      const l = this.user.recordData.location;
      if (l) return new Place(l.latitude, l.longitude, l.description);
      return undefined;
    },
  },
  methods: {
    signOut(): void {
      this.$store.dispatch('auth/signOut');
    },
    updateBio(bio: string) {
      this.$store.dispatch('auth/updateUserDetails', { bio: bio });
    },
  },
});
